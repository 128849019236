<template>
<div>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <h1 class="title mt-1">Listado</h1>
                <p class="desc mt-0 mb-1">Listado de enlaces creados.</p>
            </v-alert>
        </v-col>

        <!-- Search Bar y Filtros -->
        <v-row class="mx-3">
            <v-col cols="12" md="4" class="px-0">
                <v-text-field v-model="search" @input="fetchLinkClicks" color="#8061c2" label="Buscar" append-icon="mdi-magnify" single-line hide-details outlined class="mb-2 mx-0 px-0"></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="px-md-2 px-0">
                <v-select v-model="filter" :items="filterOptions" label="Filtrar por" outlined hide-details></v-select>
            </v-col>

            <v-col cols="12" md="4" class="px-0 ">
                <v-select v-model="order" :items="orderOptions" label="Ordenar" outlined hide-details></v-select>
            </v-col>
        </v-row>

        <!-- Tabla de datos -->
        <v-data-table :headers="headers" :items="links" :loading="loading" class="elevation-1 mx-3 px-2 py-4" :items-per-page="perPage" :server-items-length="totalLinks" :page.sync="page" :footer-props="{
          itemsPerPageOptions: [50, 100, 200, 300, -1], 
          showFirstLastPage: true
        }" @update:page="fetchLinkClicks" @update:items-per-page="changePerPage">
            <!-- Columnas y Templates -->
            <template v-slot:item.platform="{ item }">
                {{ item.platform || 'Desconocida' }}
            </template>

            <template v-slot:item.url="{ item }">
                <a :href="item.url" target="_blank">
                    {{ truncateString(item.url) }}
                </a>
            </template>

            <template v-slot:item.total_clicks="{ item }">
                {{ item.total_clicks || 0 }}
            </template>

            <template v-slot:item.unique_clicks="{ item }">
                {{ item.unique_clicks || 0 }}
            </template>

            <template v-slot:item.site_name="{ item }">
                {{ item.site_name || 'Sin nombre' }}
            </template>

            <template v-slot:item.user_email="{ item }">
                {{ item.user_email || 'Sin correo electrónico' }}
            </template>

            <template v-slot:item.created_at="{ item }">
                {{ item.created_at }}
            </template>

            <!-- Botones de acciones -->
            <template v-slot:item.actions="{ item }">
                <!-- Botón para estadísticas -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="goToStatistics(item)">
                            <v-icon>mdi-chart-line</v-icon>
                        </v-btn>
                    </template>
                    <span>Ver Estadísticas</span>
                </v-tooltip>

                <!-- Nuevo botón para ir al Harmony -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="green" v-bind="attrs" v-on="on" @click="goToHarmony(item.site_slug)">
                            <v-icon>mdi-web</v-icon>
                        </v-btn>
                    </template>
                    <span>Ir al Harmony</span>
                </v-tooltip>

                <!-- Botón para impersonar usuario con tooltip -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on" @click="impersonateUser(item.user_id)">
                            <v-icon>mdi-account-switch</v-icon>
                        </v-btn>
                    </template>
                    <span>Impersonar Usuario</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-col>
</div>
</template>

<script>
import axios from '@/axios.js';

export default {
    name: 'LinkClicks',
    data() {
        return {
            links: [],
            search: '', // Término de búsqueda
            loading: true,
            page: 1,
            perPage: 50,
            totalLinks: 0,
            order: 'desc', // Orden por defecto
            filter: 'created_at', // Filtro por defecto
            orderOptions: [{
                    text: 'Mayor',
                    value: 'desc'
                },
                {
                    text: 'Menor',
                    value: 'asc'
                }
            ],
            filterOptions: [{
                text: 'Fecha Creación',
                value: 'created_at'
            }, ],
            headers: [{
                    text: 'Harmony',
                    value: 'site_name',
                    align: 'start'
                },
                {
                    text: 'Creador',
                    value: 'user_email',
                    align: 'start'
                },
                {
                    text: 'Plataforma',
                    value: 'platform',
                    align: 'start'
                },
                {
                    text: 'URL',
                    value: 'url',
                    align: 'start'
                },
                {
                    text: 'Fecha Creación',
                    value: 'created_at',
                    align: 'start'
                },
                {
                    text: 'Acciones',
                    value: 'actions',
                    sortable: false
                }
            ]
        };
    },

    created() {
        this.initializeSearch();
        this.fetchLinkClicks();
        this.$i18n.locale = 'es';
    },
    watch: {
        order(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchLinkClicks();
            }
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchLinkClicks();
            }
        }
    },
    methods: {

        truncateString(str) {
            if (str.length > 48) {
                return str.substring(0, 48) + ' ...';
            }
            return str;
        },

        initializeSearch() {
            // Comprueba si hay una búsqueda pasada como parámetro de la URL
            const searchQuery = this.$route.query.search;
            if (searchQuery) {
                this.search = searchQuery;
            }
        },

        async impersonateUser(user_id) {
            try {
                const response = await axios.post('/admin/impersonate-user', {
                    user_id: user_id
                });

                let admin = localStorage.getItem("user");
                localStorage.setItem("impersonate_admin", JSON.stringify(admin));

                // Guardar el nuevo token y datos de usuario en el local storage
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem("token", response.data.token);

                this.$vs.notify({
                    title: 'Impersonación exitosa',
                    text: `Ahora estás logueado como ${response.data.user.first_name}`,
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });

                // Redirigir al panel correspondiente basado en el rol del usuario
                if (response.data.user.role_id === 1) {
                    this.$router.push('/panel/admin');
                } else {
                    this.$router.push('/panel');
                }
            } catch (error) {
                this.$vs.notify({
                    title: 'Ha ocurrido un error',
                    text: 'No se pudo impersonar al usuario.',
                    color: 'danger',
                    icon: 'warning',
                    position: 'bottom-center',
                });
                console.error(error);
            }
        },

        async fetchLinkClicks() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/link-clicks', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search, // Añadir el término de búsqueda a la solicitud
                        order: this.order,
                        filter: this.filter
                    }
                });

                this.links = response.data.data; // Acceder a los datos reales
                this.totalLinks = response.data.total; // Total de registros para paginación

            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1; // Reinicia la página al cambiar el número de elementos por página
            this.fetchLinkClicks(); // Vuelve a cargar los datos con la nueva configuración
        },

        goToStatistics(item) {
            const harmonyUrl = `${window.location.origin}/panel/admin/stats?id=${item.site_id}&user_id=${item.user_id}&name=${encodeURIComponent(item.site_name)}`;
            window.open(harmonyUrl, '_blank');
        },

        // Método para redirigir al Harmony utilizando el slug
        goToHarmony(slug) {
            const harmonyUrl = `${window.location.origin}/${slug}`;
            window.open(harmonyUrl, '_blank');
        }
    }
};
</script>

<style scoped>
.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}
</style>
